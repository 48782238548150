(function ($) {
    $.fn.customAffix = function (options) {
        const $self = $(this);
        const $placeholder = $(options.placeholder);
        const $offsetElement = options.offsetElement ? $(options.offsetElement) : $self;
        const $secondaryOffsetElement = options.secondaryOffsetElement ? $(options.secondaryOffsetElement) : null;
        const $classes = options.classes ? options.classes : "affix";

        // Setup scrolling variable
        let isScrolling;
        
        // Calculate our offset from the top of the document
        let stickOn = $secondaryOffsetElement ? $offsetElement.offset().top - $secondaryOffsetElement.outerHeight() : $offsetElement.offset().top;

        // Explicitly set the height on placeholder element to prevent layout issues
        $placeholder.height($self.outerHeight());

        // Watch for window to resize
        window.addEventListener("resize", function () {
            // Recalculate our placeholder element height
            $placeholder.height($self.outerHeight());

            // and if we aren't affixed
            if (!$self.hasClass($classes)) {
                // recalculate our offset from the top of the document
                stickOn = $secondaryOffsetElement ? $offsetElement.offset().top - $secondaryOffsetElement.outerHeight() : $offsetElement.offset().top;
            }
        });

        // Watch for the window to scroll
        window.addEventListener("scroll", function () {
            // Get our scroll offset
            const distanceY = window.pageYOffset || document.documentElement.scrollTop;

            // If we've reached our element top
            if (distanceY > stickOn) {
                // Add affix classes to the element
                $self.addClass($classes);
                
                // If we're using an offset element
                if (options.offsetElement) {
                    const height = $secondaryOffsetElement ? $secondaryOffsetElement.outerHeight() : $offsetElement.outerHeight();
                    
                    // Set top position of element to be the height of the offset element or secondary offset
                    $self.css("top", height);
                    // Recalculate our placeholder element height
                    $placeholder.height($self.outerHeight());
                }
                
            } else if ($self.hasClass($classes)) {
                // Else, remove the classes from the element
                $self.removeClass($classes);

                // If we're using an offset element
                if (options.offsetElement) {
                    // Set top position of element back to auto
                    $self.css("top", "auto");
                    $placeholder.height("auto");
                }

                // Clear our timeout
                window.clearTimeout(isScrolling);

                // Set a timeout to run after our current call stack finishes executing
                // to allow all sticky elements to get the proper offset value 
                isScrolling = setTimeout(function() {
                    stickOn = $secondaryOffsetElement ? $offsetElement.offset().top - $secondaryOffsetElement.outerHeight() : $offsetElement.offset().top;
                }, 0);
            }
        });
    };
})(jQuery);

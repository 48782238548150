(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        define(function () {
            return factory(root);
        });
    } else if (typeof exports === 'object') {
        module.exports = factory;
    } else {
        root.lazy = factory(root);
    }
})(this, function (root) {
    'use strict';
    const keypress = [34, 40, 38, 33];
    var lazy = {};
    var callback = function () { };
    var isScrolling;

    var offset, poll, delay, useDebounce, unload;

    var isHidden = function (element) {
        return (element.offsetParent === null);
    };

    var inView = function (element, view) {
        if (isHidden(element)) {
            return false;
        }

        var box = element.getBoundingClientRect();
        return (box.right >= view.l && box.bottom >= view.t && box.left <= view.r && box.top <= view.b);
    };

    var debounceOrThrottle = function () {
        if (!useDebounce && !!poll) {
            return;
        }
        clearTimeout(poll);
        poll = setTimeout(function () {
            lazy.render();
            poll = null;
        }, delay);
    };

    var doRender = function () {
        lazy.render();
    };

    lazy.scrollFinished = function () {
        doRender();
        if (isScrolling) {
            clearTimeout(isScrolling);
        }
    };

    lazy.init = function (opts) {
        opts = opts || {};
        var offsetAll = opts.offset || 0;
        var offsetVertical = opts.offsetVertical || offsetAll;
        var offsetHorizontal = opts.offsetHorizontal || offsetAll;
        var optionToInt = function (opt, fallback) {
            return parseInt(opt || fallback, 10);
        };

        offset = {
            t: optionToInt(opts.offsetTop, offsetVertical),
            b: optionToInt(opts.offsetBottom, offsetVertical),
            l: optionToInt(opts.offsetLeft, offsetHorizontal),
            r: optionToInt(opts.offsetRight, offsetHorizontal)
        };

        delay = optionToInt(opts.throttle, 250);
        useDebounce = opts.debounce !== false;
        unload = !!opts.unload;
        callback = opts.callback || callback;
        lazy.render();


        if (document.addEventListener) {
            root.addEventListener('scroll', function (event) {
                // Clear our timeout throughout the scroll
                window.clearTimeout(isScrolling);

                // Set a timeout to run after scrolling ends
                isScrolling = setTimeout(function () {
                    lazy.scrollFinished();
                }, 50);
            }, true);

            root.addEventListener('load', doRender, false);

            // TP 43090 - images not loading sometimes
            // This is not an ideal fix as it really shouldn't happen
            // on every click, but it's an effective fix so that when
            // the user clicks on a "next" or "prev" button, it
            // will render the images.
            root.addEventListener('click', doRender, false);

            root.addEventListener("keydown", function (event) {
                if (keypress.indexOf(event.which) >= 0) {
                    doRender();
                }
            });
        } else {
            root.attachEvent('onscroll', debounceOrThrottle);
            root.attachEvent('onload', debounceOrThrottle);
        }
    };

    lazy.render = function (context) {
        var nodes = (context || document).querySelectorAll('[data-lazy], [data-lazy-background]');
        var length = nodes.length;
        var src, elem;
        var view = {
            l: 0 - offset.l,
            t: 0 - offset.t,
            b: (root.innerHeight || document.documentElement.clientHeight) + offset.b,
            r: (root.innerWidth || document.documentElement.clientWidth) + offset.r
        };
        for (var i = 0; i < length; i++) {
            elem = nodes[i];
            if (inView(elem, view)) {

                if (unload) {
                    elem.setAttribute('data-lazy-placeholder', elem.src);
                }

                if (elem.getAttribute('data-lazy-background') !== null) {
                    elem.style.backgroundImage = 'url(' + elem.getAttribute('data-lazy-background') + ')';
                }
                else if (elem.src !== (src = elem.getAttribute('data-lazy'))) {
                    elem.src = src;
                }

                if (!unload) {
                    elem.removeAttribute('data-lazy');
                    elem.removeAttribute('data-lazy-background');
                }

                callback(elem, 'load');
            }
            else if (unload && !!(src = elem.getAttribute('data-lazy-placeholder'))) {

                if (elem.getAttribute('data-lazy-background') !== null) {
                    elem.style.backgroundImage = 'url(' + src + ')';
                }
                else {
                    elem.src = src;
                }

                elem.removeAttribute('data-lazy-placeholder');
                callback(elem, 'unload');
            }
        }

        if (!length) {
            lazy.detach();
        }
    };

    lazy.detach = function () {
        if (document.removeEventListener) {
            root.removeEventListener('scroll', debounceOrThrottle);
        } else {
            root.detachEvent('onscroll', debounceOrThrottle);
        }
        clearTimeout(poll);
    };

    return lazy;
});
(function ($) {
    $.fn.reload = function (options) {
  
        let $self = $(this);

        if ($self.length > 1) {
            $self.each(function (i, el) {
                $(el).reload(options);
            });
            return $self;
        }

        $self.bind('load', function (e) {
            if (e.target.src.indexOf(options.loadingImage) === -1) {
                $self.removeLoading();
            }
        });

        $self.bind('error', function () {
            const failingImage = $self.attr('src');
            if (options.loadingImage) {
                $self.attr('src', options.loadingImage);
                $self.attr('loading', 'loading');
                $self.parent().addClass(options.reloadClass);
                $self.parent().removeClass('initial');
            }
            var retries = $self.attr('data-retry-count');
            if (retries < options.retryCount) {
                setTimeout(function () {
                    $self.attr('src', failingImage);
                    retries++;
                    $self.attr('data-retry-count', retries);
                }, options.reloadTime);

            } else {
                if (options.failedImage) {
                    $self.removeClass('default-src');
                    $self.addClass('default-src-error');
                    $self.attr('src', options.failedImage);
                    $self.removeLoading();
                }
            }
        });

        $self.removeLoading = function() {
            if ($self.attr('loading')) {
                $self.removeAttr('loading');
            }
            $self.parent().removeClass(options.reloadClass);
        }
        return this;

    };
})(jQuery);

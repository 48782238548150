// Exports
var InventoryModule_ModelSearch_success;
var InventoryModule_ModelSearch_failure;
var InventoryModule_ModelSearch_complete;
var InventoryModule_ModelSearch_begin;

var InventoryModule_SubmitLead_success;
var InventoryModule_SubmitLead_failure;
var InventoryModule_SubmitLead_begin;
var InventoryModule_SubmitLead_complete;

var filters = {};

// jQuery Plugin
(function($) {
    $.fn.inventoryModule = function() {
        $(this).each(function(i, e) {
            const $module = $(e);

            const ariaHide = function () {
                $(this).attr('aria-hidden', true);
            }
            const ariaShow = function () {
                $(this).attr('aria-hidden', false);
            }

            adjustStickyFooterPosition();

            const $generalInquiryForm = $module.find(".data-general-inquiry-form");
            const $vehicleInquiryEmailModal = $module.find(".data-email-modal");
            const $generalInquiryEmailModal = $module.find(".data-general-inquiry-email-modal");
            const $emailForModal = $module.find('.price-miles-email-modal');
            const $valueMyTradeModal = $module.find(".data-value-my-trade-modal");
            const $textDealerModal = $module.find(".data-text-modal");
            const $callDealerModal = $module.find(".data-call-dealer-modal");
            const $successModal = $module.find(".data-modal-success");
            const $failureModal = $module.find(".data-modal-error");
            const $textDealership = $module.find(".data-text-form");
            const $emailDealership = $module.find(".data-email-form");

            const $generalInquiryFormComments = $generalInquiryForm.find(".data-comments-field");
            if ($generalInquiryFormComments.length) {
                $generalInquiryFormComments.characterCounter({
                    maxLength: 1000
                });
            }

            const $vehicleInquiryEmailComments = $vehicleInquiryEmailModal.find(".data-comments-field");
            if ($vehicleInquiryEmailComments.length) {
                $vehicleInquiryEmailComments.characterCounter({
                    maxLength: 1000
                });
            }

            const $valueMyTradeComments = $valueMyTradeModal.find(".data-comments-field");
            if ($valueMyTradeComments.length) {
                $valueMyTradeComments.characterCounter({
                    maxLength: 1000
                });
            }

            const $textComments = $textDealerModal.find(".data-comments-field");
            if ($textComments.length) {
                $textComments.characterCounter({
                    maxLength: 1000
                });
            }

            const $textFormComments = $textDealership.find("#text-modal-comments");
            if ($textFormComments.length) {
                $textFormComments.characterCounter({
                    maxLength: 1000
                });
            }

            const $emailFormComments = $emailDealership.find("#general-email-modal-comments");
            if ($emailFormComments.length) {
                $emailFormComments.characterCounter({
                    maxLength: 1000
                });
            }

            const $emailForFormComments = $emailForModal.find('.data-comments-field');
            if ($emailForFormComments.length) {
                $emailForFormComments.characterCounter({
                    maxLength: 1000
                });
            }

            $module.data("successModal", $successModal);
            $module.data("failureModal", $failureModal);

            $module.find("form").data("module", $module);

            $generalInquiryForm.validate($.validationRules.inquiry);
            $vehicleInquiryEmailModal.find(".data-email-form").validate($.validationRules.inquiry);
            $generalInquiryEmailModal.find(".data-email-form").validate($.validationRules.inquiry);
            $valueMyTradeModal.find(".data-email-form").validate($.validationRules.inquiry);
            $generalInquiryEmailModal.find(".data-text-form").validate($.validationRules.text);
            $emailForModal.find('.data-email-for-form').validate($.validationRules.inquiry);

            $vehicleInquiryEmailModal.on("show.bs.modal", ariaShow);
            $vehicleInquiryEmailModal.on("hide.bs.modal", ariaHide);
            $generalInquiryEmailModal.on("show.bs.modal", ariaShow);
            $generalInquiryEmailModal.on("hide.bs.modal", ariaHide);
            $valueMyTradeModal.on("show.bs.modal", ariaShow);
            $valueMyTradeModal.on("hide.bs.modal", ariaHide);
            $textDealerModal.on("show.bs.modal", ariaShow);
            $textDealerModal.on("hide.bs.modal", ariaHide);
            $callDealerModal.on("show.bs.modal", ariaShow);
            $callDealerModal.on("hide.bs.modal", ariaHide);
            $emailForModal.on("show.bs.modal", ariaShow);
            $emailForModal.on("hide.bs.modal", ariaHide);

            $generalInquiryEmailModal.on("click", '.leadTabs', function(e) {
                $(this).closest('label').tab('show');
            });

            $module.on("click", ".data-button-prev-page", function(e) {
                var $pageNumberElement = $module.find(".data-page-number");
                var pageNumber = parseInt($pageNumberElement.val());
                $pageNumberElement.val(pageNumber - 1);
                $module.find("form.data-inventory-search-form").submit();

                $("html, body").animate({
                    scrollTop: $module.offset().top
                }, 500);

                e.preventDefault();
            });

            $module.on("click", ".data-button-next-page", function(e) {
                var $pageNumberElement = $module.find(".data-page-number");
                var pageNumber = parseInt($pageNumberElement.val());
                $pageNumberElement.val(pageNumber + 1);
                $module.find("form.data-inventory-search-form").submit();

                $("html, body").animate({
                    scrollTop: $module.offset().top
                }, 500);

                e.preventDefault();
            });

            function handleEmailClick(e, data, $emailModal) {
                var $form = $emailModal.find(".data-email-form");
                $form.find("input.data-global-inventory-id").val(data["globalInventoryId"]);
                $form.find("input.data-vehicle-source-id").val(data["vehicleSourceId"]);
                $form.find("input.data-vehicle-source-external-id").val(data["vehicleSourceExternalId"]);
                $emailModal.find("span.data-email-header").text(" " + data["headerName"]);
                $form.find("textarea.data-comments-field").val(data["vehicleComments"]);

                $emailModal.modal({
                    show: true,
                    keyboard: true
                });
                e.preventDefault();
            }

            $module.on("click", ".data-general-inquiry-email", function (e) {
                handleEmailClick(e, $(this).data(), $generalInquiryEmailModal);
            });
            $module.on("click", ".data-value-my-trade", function (e) {
                const data = $(this).data();
                $valueMyTradeModal.find("textarea.data-comments-field").val(data["dialogComments"]);
                $valueMyTradeModal.modal({ show: true, keyboard: true });
                e.preventDefault();
            });

            $module.on("click", ".data-mobile-filters", function (e) {                
                filters = {
                    location: $(".data-location-field").val(),
                    condition: $(".data-condition-field").val(),
                    bodystyle: $(".data-bodystyle-field").val(),
                    make: $(".data-make-field").val(),
                    model: $(".data-model-field").val(),
                    maxprice: $(".data-max-price-field").val(),
                    mileage: $(".data-max-mileage-field").val(),
                    soldstatus: $(".data-sold-status-field").val()
                };
            });

            $module.on('click', '.data-filters-clear', function () {
                $(".base-filters .form-control").val("");
                $module.find(".data-sold-status-field").val("AllVehicles");
                $module.find(".data-sort").val("MakeAsc");
                $module.find(".data-stock-field, .data-mobile-stock-field, .data-model-field").val("");
                $module.find(".data-model-field").prop("disabled", true);
                $module.find(".data-inventory-search-form").submit();
                $module.find('.are-you-sure').removeClass("in");
            });

            $module.on("click", ".data-mobile-filters", function () {
                $(".base-filters__header").removeClass("box-group__header");
                $(".base-filters").removeClass("base-filters--hidden");
            });

            $module.on("click", ".data-filters-cancel", function () {
                if (filters) {
                    $module.find(".data-location-field").val(filters.location);
                    $module.find(".data-condition-field").val(filters.condition);
                    $module.find(".data-bodystyle-field").val(filters.bodystyle);
                    $module.find(".data-make-field").val(filters.make);
                    $module.find(".data-model-field").val(filters.model);
                    $module.find(".data-max-price-field").val(filters.maxprice);
                    $module.find(".data-max-mileage-field").val(filters.mileage);
                    $module.find(".data-sold-status-field").val(filters.soldstatus);
                    $module.find(".data-stock-field, .data-mobile-stock-field").val("");

                    $module.find(".data-inventory-search-form").submit();
                }
                
                $(".base-filters__header").addClass("box-group__header");
                $(".base-filters").addClass("base-filters--hidden");
            });            

            $module.on("click", ".data-email", function (e) {
                var $form = $generalInquiryEmailModal.find(".data-email-form");
                var data = $(this).data();

                $form.find("input.data-global-inventory-id").val(data["globalInventoryId"]);
                $form.find("input.data-vehicle-source-id").val(data["vehicleSourceId"]);
                $form.find("input.data-vehicle-source-external-id").val(data["vehicleSourceExternalId"]);
                $form.find("textarea.data-comments-field").val(data["emailComments"]);

                //Setting the text stuff too 
                var $textForm = $generalInquiryEmailModal.find(".data-text-form");
                var globalInventoryId = data["globalInventoryId"];
                $textForm.find("input.data-global-inventory-id").val(globalInventoryId);
                $textForm.find("textarea.data-comments-field").val(data["textComments"]);

                if (!!globalInventoryId && globalInventoryId > 0) {
                    // Unhide trade-in and services fields
                    $textForm.find(".data-text-badges").removeClass("hidden");
                    $textForm.find(".data-text-trade-in").removeClass("hidden");

                    // Re-enable trade-in and services checkboxes and fields
                    $textForm.find(".data-text-badges input").prop("disabled", false);
                    $textForm.find(".data-text-trade-in input").prop("disabled", false);
                } else {
                    // Hide trade-in and services fields
                    $textForm.find(".data-text-badges").addClass("hidden");
                    $textForm.find(".data-text-trade-in").addClass("hidden");

                    // Disable trade-in and services checkboxes and fields
                    $textForm.find(".data-text-badges input").prop("disabled", true);
                    $textForm.find(".data-text-trade-in input").prop("disabled", true);
                }
                ///

                $generalInquiryEmailModal.find('[id*=email-dealer-modal]').addClass('active');
                $generalInquiryEmailModal.find('[id*=text-dealer-modal]').removeClass('active');

                $('#liEmail').addClass('active');
                $('#liText').removeClass('active');
                $('#rbEmailLead').prop('checked', true);

                $generalInquiryEmailModal.modal({
                    show: true,
                    keyboard: true
                });
                e.preventDefault();
            });

            $module.on('click', '.data-email-for-mileage, .data-email-for-price', function (e) {
                var $form = $emailForModal.find('.lead-form');
                var data = $(this).data();

                $form.find("input.data-global-inventory-id").val(data["globalInventoryId"]);
                $form.find("input.data-vehicle-source-id").val(data["vehicleSourceId"]);
                $form.find("input.data-vehicle-source-external-id").val(data["vehicleSourceExternalId"]);
                $form.find("textarea.data-comments-field").val(data["emailComments"]);
                $emailForModal.find('.modal-title').html('<i class="glyphicon glyphicon-envelope"></i> ' + data['headerName']);

                $emailForModal.modal({
                    show: true,
                    keyboard: true
                });
                e.preventDefault();
            });

            $('#footer-email-open-modal').click(function () {
                $('[id*=email-dealer-modal]').addClass('active');
                $('[id*=text-dealer-modal]').removeClass('active');

                $('#liEmail').addClass('active');
                $('#liText').removeClass('active');
                $('#rbEmailLead').prop('checked', true);
            });

            $('#email-dealer-modal').click(function () {
                $('[id*=email-dealer-modal]').addClass('active');
                $('[id*=text-dealer-modal]').removeClass('active');

                $('#liEmail').addClass('active');
                $('#liText').removeClass('active');
                $('#rbEmailLead').prop('checked', true);
            });

            $('#text-dealer-modal').click(function () {
                $('[id*=text-dealer-modal]').addClass('active');
                $('[id*=email-dealer-modal]').removeClass('active');

                $('#liText').addClass('active');
                $('#liEmail').removeClass('active');
                $('#rbTextLead').prop('checked', true);
            });

            $module.on("click", ".data-text-dealer", function (e) {
                var data = $(this).data();
                var $textForm = $generalInquiryEmailModal.find(".data-text-form");
                var globalInventoryId = data["globalInventoryId"];
                $textForm.find("input.data-global-inventory-id").val(globalInventoryId);
                $textForm.find("textarea.data-comments-field").val(data["textComments"]);

                if (!!globalInventoryId && globalInventoryId > 0) {
                    // Unhide trade-in and services fields
                    $textForm.find(".data-text-badges").removeClass("hidden");
                    $textForm.find(".data-text-trade-in").removeClass("hidden");

                    // Re-enable trade-in and services checkboxes and fields
                    $textForm.find(".data-text-badges input").prop("disabled", false);
                    $textForm.find(".data-text-trade-in input").prop("disabled", false);
                } else {
                    // Hide trade-in and services fields
                    $textForm.find(".data-text-badges").addClass("hidden");
                    $textForm.find(".data-text-trade-in").addClass("hidden");
                    
                    // Disable trade-in and services checkboxes and fields
                    $textForm.find(".data-text-badges input").prop("disabled", true);
                    $textForm.find(".data-text-trade-in input").prop("disabled", true);
                }

                //Set Email Form
                var $form = $generalInquiryEmailModal.find(".data-email-form");
                $form.find("input.data-global-inventory-id").val(data["globalInventoryId"]);
                $form.find("input.data-vehicle-source-id").val(data["vehicleSourceId"]);
                $form.find("input.data-vehicle-source-external-id").val(data["vehicleSourceExternalId"]);
                $form.find("textarea.data-comments-field").val(data["emailComments"]);
                ///

                $generalInquiryEmailModal.find('[id*=text-dealer-modal]').addClass('active');
                $generalInquiryEmailModal.find('[id*=email-dealer-modal]').removeClass('active');

                $('#liText').addClass('active');
                $('#liEmail').removeClass('active');
                $('#rbTextLead').prop('checked', true);

                $generalInquiryEmailModal.modal({
                    show: true,
                    keyboard: true
                });
                e.preventDefault();
            });

            $module.on("click", ".data-call-dealer", function (e) {
                var phoneNumber = $(this).data('phoneNumber');
                var phoneNumberFormatted = $(this).data('phoneNumberFormatted');
                $callDealerModal.find(".js-phone-link").attr("href", "tel:" + phoneNumber);
                $callDealerModal.find(".js-phone-text").text(" " + phoneNumberFormatted);
                $callDealerModal.modal({
                    show: true,
                    keyboard: true
                });
                e.preventDefault();
            });

            $module.find("form.data-inventory-search-form")
                .on("submit",
                    function (e) {
                        var $stockNumber = $(e.target).find("input[name='StockNumber']");
                        var val = $stockNumber.val();

                        if (val !== "") {
                            $(e.target).find("select").each(function () {
                                if (this.name !== "Sort") {
                                    $(this).val($(this).find("option:first").val());
                                }
                            });
                        }
                    });

            $module.find(".data-location-field")
                .change(function (event) {
                    $(".data-page-number").val(1);
                    $(event.target.form).find("input[name='StockNumber']").val("");
                    $(event.target.form).submit();
                    $(".seonamedlinks,.seo-footer").hide();
                });

            $module.find('.data-bodystyle-field')
				.change(function (event) {
					$(".data-page-number").val(1);
					$(event.target.form).find("input[name='StockNumber']").val("");
                    $(event.target.form).submit();
                    $(".seonamedlinks,.seo-footer").hide();
				});
            
            $('#Sort option[value*="Model"]').hide();
            $module.find(".data-make-field").change(function (event) {
                var selectedMake = $(this).val();
                var $make = $module.find('.data-make-field')
                var $model = $module.find(".data-model-field");
                var sort = $('#Sort');
                var sortVal = sort.val() || '';

                if (selectedMake === "") {
                    $model.val(null);
                    $model.prop("disabled", true);
                    $('#Sort option[value*="Make"]').show();
                    $('#Sort option[value*="Model"]').hide();

                    if (sortVal === '') {
                        sort.val('MakeAsc').change();
                    }

                    $('.js-most-relevant').remove();
                } else {
                    $make.val(selectedMake);
                    $model.val("");
                    $.post({
                        url: $(this).data("modelsEndpoint"),
                        data: { make: selectedMake },
                        success: function (data) {
                            $model.find("option:not([value=''])").remove();

                            $.each(data, function (index, value) {
                                $("<option />").attr("value", value.Name).text(value.Name + " (" + value.Count + ")").appendTo($model);
                            });

                            if (data.length > 0)
                            {
                                $model.prop("disabled", false);
                            }

                            $('#Sort option[value*="Model"]').show();

                            if ($('#Sort').val() && ~$('#Sort').val().indexOf('Make')) {
                                $('#Sort').val('ModelAsc').change();
                            }

                            $('#Sort option[value*="Make"]').hide();

                            if (!$('.js-most-relevant').length && sortVal.indexOf('Make') > -1) {
                                sort.append('<option class="js-most-relevant" value="">Most Relevant</option>').val('').change();
                            }
                        },
                        error: function(jqXhr, textStatus, errorThrown) {
                            // TODO (ljd - 3/15/16) - Add proper error handling
                        }
                    });
                }

                $(".data-page-number").val(1);
                $(event.target.form).find("input[name='StockNumber']").val("");                
                $(event.target.form).submit();
                $(".seonamedlinks,.seo-footer").hide();
            });

            $module.find(".data-model-field")
                .change(function (event) {
                    $(".data-page-number").val(1);
                    $(event.target.form).find("input[name='StockNumber']").val("");
                    $(event.target.form).submit();
                    $(".seonamedlinks,.seo-footer").hide();
                });

            $module.find(".data-max-price-field")
                .change(function (event) {
                    $(".data-page-number").val(1);
                    $(event.target.form).find("input[name='StockNumber']").val("");
                    $(event.target.form).submit();
                });

            $module.find('.data-condition-field')
				.change(function (event) {
					$(".data-page-number").val(1);
					$(event.target.form).find('input[name="StockNumber"]').val("");
					$(event.target.form).submit();
				});

            $module.find('.data-sold-status-field')
				.change(function (event) {
					$(".data-page-number").val(1);
					$(event.target.form).find("input[name='StockNumber']").val("");
					$(event.target.form).submit();
				});

            $module.find(".data-max-mileage-field")
                .change(function (event) {
                    $(".data-page-number").val(1);
                    $(event.target.form).find("input[name='StockNumber']").val("");
                    $(event.target.form).submit();
                });

            $module.find(".data-apply-filter").on("click", function (event) {
                var soldstatus = $module.find(".data-mobile-stock-field").val();
                if (soldstatus !== "") {
                    $module.find(".data-stock-field").val(soldstatus);
                    $(event.target.form).submit();
                }
                
                $(".base-filters__header").addClass("box-group__header");
                $(".base-filters").addClass("base-filters--hidden");
            });            

            /*
                * When you submit a form through a jquery .submit() call, there is no
                * original event on the object. The pagination buttons use a call to
                * submit(), so they do not have the property. When you submit through
                * the form at the top of the page through clicking the button or
                * pressing enter on a field, there is an event and we want to reset
                * the page number, otherwise it may try to display a page that doesn't
                * exist.
                */
            // HACK (ljd - 3/10/16) - is there a better solution?
            $module.find("form.data-inventory-search-form").submit(function(event) {
                if (typeof (event.originalEvent) !== "undefined") {
                    var $pageNumberElement = $module.find(".data-page-number");
                    $pageNumberElement.val(1);
                }
            });

            $module.find('select.page-size-select')
                .change(function () {
                    $(".data-page-number").val(1);
                    $(this).closest("form").submit();
                });

            $module.find("select.sort-select")
                .change(function () {
                    $(".data-page-number").val(1);
                    $(this).closest("form").submit();
                });

            $vehicleInquiryEmailModal.appendTo("body");
            $generalInquiryEmailModal.appendTo("body");
            $valueMyTradeModal.appendTo("body");
            $textDealerModal.appendTo("body");
            $callDealerModal.appendTo("body");
            $successModal.appendTo("body");
            $failureModal.appendTo("body");
        });
    };

    // External Callback Function
    function modelSearchSuccess(data) {
        /*
         * Within the callback, the `this` object refers to the form element, so to get the module,
         * find the module class on the parent and modify that markup.
         */
        let $this = $(this);        
        var $module = $this.parents('.data-inventory-module');

        $module.find(".loading-section-parent").hide();

        $module.find(".data-result-table").html(data);

        var oldBreadcrumbs = $module.find(".data-breadcrumb-holder .data-inventory-breadcrumb");
        let $newBreadcrumbs = $module.find(".data-new-breadcrumb .data-inventory-breadcrumb");

        oldBreadcrumbs.replaceWith($newBreadcrumbs);

        // don't use "this" to get the form.  "this" is the old form, not the newly loaded one
        let seoTitle = $newBreadcrumbs.data("seo-title");
        let searchUrl = $this.data("cars-for-sale-url");

        // Do not put the security token into the querystring
        window.history.replaceState(null, seoTitle, searchUrl + "?" + $("select,input[name!='__RequestVerificationToken']", this).serialize());
        $(document).attr("title", seoTitle);

        // Update Results shown data and text
        var newTotalRecords = $(data).find('.data-inventory-total-records').val();
        $module.find('.data-inventory-total-records').text(newTotalRecords);

        var newResultsText = $(data).find('.data-inventory-results-text').val();
        $module.find('.data-inventory-results-text').html(newResultsText);
    }

    InventoryModule_ModelSearch_success = modelSearchSuccess;

    // External Callback Function
    function modelSearchFailure() {
        var $module = $(this).parents(".data-inventory-module");
        $module.find(".loading-section-parent").hide();
        $module.find(".data-result-table").html("<div class='bg-danger text-center data-result-warning'>There was a problem loading the vehicles. Please try again!</div>");
    }

    InventoryModule_ModelSearch_failure = modelSearchFailure;

    function modelSearchComplete() {
        $(window).trigger('resize.px.parallax');
    }
    InventoryModule_ModelSearch_complete = modelSearchComplete;

    // External Callback Function
    function modelSearchBegin() {
        var $module = $(this).parents(".data-inventory-module");

        $module.find(".data-result-warning").remove();
        $module.find(".loading-section-parent").show();
    }

    InventoryModule_ModelSearch_begin = modelSearchBegin;

    // External Callback Function
    InventoryModule_SubmitLead_success = function (data) {
        var $module = $(this).data("module");

        if (data.success) {
            $module.data("successModal").modal("show");
        } else {
            $module.data("failureModal").modal("show");
        }
    };

    InventoryModule_SubmitLead_failure = function () {
        $(this).data("module").data("failureModal").modal("show");
    };

    InventoryModule_SubmitLead_begin = function () {
        var $this = $(this);
        var modal = $this.parents(".modal");
        modal.modal("hide");
    };

    InventoryModule_SubmitLead_complete = function () {
        // Get a new recaptcha token
        grecaptcha.ready(function () {
            grecaptcha.execute("6LdvybMgAAAAACtTT_p38LRFXSWMB2ObtjGrtdpZ", { action: 'submit' }).then(function (token) {
                $('.recaptcha-response').val(token);
            });
        });
    };

    // Default elements to turn into inventory modules
    $(function() {
        $(".data-inventory-module").inventoryModule();
    });

})(jQuery);

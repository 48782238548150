$(function () {
    var TOGGLE_OPEN_CLASS = 'seo-toggle--open';
    var TOGGLE_CLOSE_CLASS = 'seo-toggle--closed';
    var CONTENT_OPEN_CLASS = 'seo-panel--open';
    var CONTENT_CLOSE_CLASS = 'seo-panel--closed';

    /* Smooth collapsing code ported from: https://css-tricks.com/using-css-transitions-auto-dimensions/ */

    function PanelCollapser(panelEl) {
        this.panel = panelEl;
        this.toggle = panelEl.querySelector('.js-collapsetoggle');
        this.content = panelEl.querySelector('.js-collapsepanel');

        this.addToggleHandler();

        if (this.shouldAllowCollapse()) {
            this.collapsePanel();
        } else {
            this.openPanel();
        }
    }

    PanelCollapser.prototype.shouldAllowCollapse = function () {
        return window.matchMedia('(max-width: 768px)').matches;
    };

    PanelCollapser.prototype.openPanel = function () {
        this.toggle.classList.add(TOGGLE_OPEN_CLASS);
        this.content.classList.add(CONTENT_OPEN_CLASS);

        this.toggle.classList.remove(TOGGLE_CLOSE_CLASS);
        this.content.classList.remove(CONTENT_CLOSE_CLASS);

        this.openContent();

        this.isCollapsed = false;
    };

    PanelCollapser.prototype.openContent = function () {
        var sectionHeight = this.content.scrollHeight;
        this.content.style.height = sectionHeight + 'px';
    };

    PanelCollapser.prototype.collapsePanel = function () {
        this.toggle.classList.add(TOGGLE_CLOSE_CLASS);
        this.content.classList.add(CONTENT_CLOSE_CLASS);

        this.toggle.classList.remove(TOGGLE_OPEN_CLASS);
        this.content.classList.remove(CONTENT_OPEN_CLASS);

        this.collapseContent();

        this.isCollapsed = true;
    };

    PanelCollapser.prototype.collapseContent = function () {
        var sectionHeight = this.content.scrollHeight;

        var elementTransition = this.content.style.transition;
        this.content.style.transition = '';

        window.requestAnimationFrame(function () {
            this.content.style.height = sectionHeight + 'px';
            this.content.style.transition = elementTransition;

            window.requestAnimationFrame(function () {
                this.content.style.height = '0px';
            }.bind(this));
        }.bind(this));
    };

    PanelCollapser.prototype.togglePanel = function () {
        if (this.isCollapsed) {
            this.openPanel();
        } else {
            this.collapsePanel();
        }

        this.content.setAttribute('aria-expanded', !this.isCollapsed);
    };

    PanelCollapser.prototype.addToggleHandler = function () {
        this.toggle.addEventListener('click', function (ev) {
            ev.preventDefault();

            if (this.shouldAllowCollapse()) {
                this.togglePanel();
            }
        }.bind(this));
    };

    var collapsers = document.querySelectorAll('.js-collapser');

    if (collapsers.length) {
        for (var i = 0; i < collapsers.length; i++) {
            new PanelCollapser(collapsers[i]);
        }
    }
});

$(document).ready(function () {
    var aboutContent = $('.h-about-m1 > .spotlight > .content');
    var elm = aboutContent.find('.about-text');
    var htmlElm = $('html');

    var toggleButton = aboutContent.find('.viewToggleButton').click(function () {
        if (toggleButton.text() === 'View Less') {
            var contentTop = aboutContent.offset().top - (window.innerHeight * .2);

            if (contentTop < htmlElm.scrollTop()) {
                htmlElm.animate({
                    scrollTop: contentTop
                });
            }

            elm.css('height', '134px').css('-webkit-line-clamp', '6').css('display', '-webkit-box');

            toggleButton.text('View More');
        } else {
            elm.css('height', 'auto').css('display', '').css('-webkit-line-clamp', 'initial');

            toggleButton.text('View Less');
        }
    });
});

var DetailsModule_InquiryOnBegin;
var DetailsModule_InquiryOnSuccess;
var DetailsModule_InquiryOnFailure;
var DetailsModule_InquiryOnComplete;
var DetailsModule_ShowVideoModalOnLoad;

(function ($) {
    $('input[name="intervaltype"]').click(function () {
        $(this).closest('label').tab('show');
    });

    $('input[name="onPageForm"]').click(function () {
        $(this).closest('label').tab('show');
    });

    $('input[name="onPageForm2"]').click(function () {
        $(this).closest('label').tab('show');
    });

    var $headerBar = $(".vdp-header-bar");

    if ($headerBar.length) {
        var $headerBarWrapper= $(".vdp-header-bar-wrapper");
        var $mainMenu = $(".main-menu");
        var $pageHeader = $(".header");
        var $offsetElement = $mainMenu;
        var $secondaryOffsetElement;
        
        // Check if the nav menu is positioned before the page header (if present), relative to the viewport
        if ($pageHeader.length && ($mainMenu[0].getBoundingClientRect().top < $pageHeader[0].getBoundingClientRect().top)) {
            $offsetElement = $headerBarWrapper;
            $secondaryOffsetElement = $mainMenu;
        }
        
        // Makes header bar sticky
        $headerBar.customAffix({
            placeholder: $headerBarWrapper,
            classes: "vdp-header-bar--sticky",
            offsetElement: $offsetElement,
            secondaryOffsetElement: $secondaryOffsetElement
        });
    }

    $.fn.detailsModule = function() {
        $(this)
            .each(function (i, e) {
                var $module = $(e);

                const ariaHide = function () {
                    $(this).attr('aria-hidden', true);
                };

                const ariaShow = function () {
                    $(this).attr('aria-hidden', false);
                };

                $module.find("form").data("module", $module);

                leadFormTabs($(".vdp-contact-block"));
                leadFormTabs($(".vdp-contact-block-mobile"));
                adjustStickyFooterPosition();

                const $emailDealership = $module.find(".data-contact-dealership");
                const $emailDealershipMain = $module.find(".data-contact-dealership-main");
                const $emailDealershipNew = $module.find(".data-contact-dealership-new");
                const $textDealership = $module.find(".data-text-dealership");
                const $textDealershipMobile = $module.find('.data-text-dealership-mobile');
                const $emailDealershipModal = $module.find(".data-contact-dealership-mobile");
                const $textDealerModal = $module.find(".data-text-dealership-modal");
                const $callDealerModal = $module.find(".data-call-dealer-modal");
                const $emailPriceMiles = $module.find(".contact-email-price-mileage");                

                //Initialize text leads forms, if they're present
                if ($textDealership.length > 0) {
                    $textDealership.validate($.validationRules.text);

                    const $textComments = $textDealership.find("#leadtext_comments");
                    $textComments.characterCounter({
                        maxLength: 1000
                    });
                }
                if ($textDealershipMobile.length > 0) {
                    $textDealershipMobile.validate($.validationRules.text);

                    const $textComments = $textDealershipMobile.find("#leadtext-mobile_comments");
                    $textComments.characterCounter({
                        maxLength: 1000
                    });
                }
                if ($emailDealership.length > 0) {
                    $emailDealership.validate($.validationRules.inquiry);

                    const $emailComments = $emailDealership.find("#leadvehicleinquiry_comments");
                    $emailComments.characterCounter({
                        maxLength: 1000
                    });
                }
                if ($emailDealershipMain.length > 0) {
                    $emailDealershipMain.validate($.validationRules.inquiry);

                    const $emailComments = $emailDealershipMain.find("#leadvehicleinquiry_comments");
                    $emailComments.characterCounter({
                        maxLength: 1000
                    });
                }
                if ($emailDealershipNew.length > 0) {
                    $emailDealershipNew.validate($.validationRules.inquiry);

                    const $emailComments = $emailDealershipNew.find("#leadvehicleinquiry_comments");
                    $emailComments.characterCounter({
                        maxLength: 1000
                    });
                }
                if ($emailDealershipModal.length > 0) {
                    $emailDealershipModal.validate($.validationRules.inquiry);

                    const $emailTextModalEmailComments = $emailDealershipModal.find("#leadvehicleinquirymobile_comments");
                    $emailTextModalEmailComments.characterCounter({
                        maxLength: 1000
                    });
                }
                if ($textDealerModal.length > 0) {
                    $textDealerModal.validate($.validationRules.text);

                    const $emailTextModalTextComments = $textDealerModal.find("textarea.text-comments");
                    $('.text-comments').characterCounter({
                        maxLength: 1000
                    });
                }
                if ($emailPriceMiles.length > 0) {
                    $emailPriceMiles.validate($.validationRules.inquiry);

                    const $emailFormComments = $emailPriceMiles.find("#emailfor_comments");
                    $emailFormComments.characterCounter({
                        maxLength: 1000
                    });
                }

                $module.find(".valuemytrade-modal form").validate($.validationRules.inquiry);
                $module.find(".request-vin-modal form").validate($.validationRules.request_vin);

                const $photoModal = $module.find(".photo-stream-modal");
                const $videoModal = $module.find(".video-modal");
                const $vinModal = $module.find(".request-vin-modal");
                const $successModal = $module.find(".data-modal-success");
                const $failureModal = $module.find(".data-modal-error");
                const $optionsModal = $module.find(".all-options-modal");
                const $inquiryModal = $module.find(".inquiry-modal");
                const $valuemytradeModal = $module.find(".valuemytrade-modal");
                const $textModal = $module.find(".text-modal");

                [$photoModal, $videoModal, $vinModal, $optionsModal, $inquiryModal, $textModal, $callDealerModal, $valuemytradeModal].forEach(function (modal) {
                    modal.on("show.bs.modal", ariaShow);
                    modal.on("hide.bs.modal", ariaHide);
                });

                $module.data("photoModal", $photoModal);
                $module.data("videoModal", $videoModal);
                $module.data("vinModal", $vinModal);
                $module.data("successModal", $successModal);
                $module.data("failureModal", $failureModal);
                $module.data("optionsModal", $optionsModal);
                $module.data("inquiryModal", $inquiryModal);
                $module.data("$valuemytradeModal", $valuemytradeModal);
                $module.data("textModal", $textModal);


                const $valuemytradeModalComments = $valuemytradeModal.find("#valuemytrade-modal-comments");
                $valuemytradeModalComments.characterCounter({
                    maxLength: 1000
                });

                const onShowInquiry = function (e) {
                    var $modal = $(e.target);
                    var $source = $(e.relatedTarget);

                    var title = $source.data("dialogTitle");
                    var comment = $source.data("dialogComments");

                    const $commentsField = $modal.find(".data-comments");

                    $modal.find(".modal-title").html(title);
                    $commentsField.val(comment);
                };

                $inquiryModal.on("shown.bs.modal", onShowInquiry);
                $valuemytradeModal.on("shown.bs.modal", onShowInquiry);

                //set up the video modal
                if ($videoModal.data("videourl") !== undefined) {
                    var videoUrl = $videoModal.data("videourl");
                    if (videoUrl.indexOf("www.youtube.com/watch?v=") !== -1) {
                        $videoModal.data("videourl", 'https://www.youtube.com/embed/' + videoUrl.split('=')[1]);
                    }
                    else if (videoUrl.indexOf("youtu.be/") !== -1) {
                        $videoModal.data("videourl", 'https://www.youtube.com/embed/' + videoUrl.split("be/")[1]);
                    }
                    else if (videoUrl.indexOf("www.facebook.com") !== -1) {
                        var url = "https://www.facebook.com/plugins/video.php?href=" + videoUrl + "&show_text=0&width=560&height=315";
                        $videoModal.data("videourl", url);
                    }
                }

                const $videoModaliFrame = $videoModal.find('iframe');
                const $aspectRatio = [$videoModaliFrame.attr('width'), $videoModaliFrame.attr('height')];
                let $newWidth = 0;

                $videoModal.on('hidden.bs.modal', function () {
                    $videoModaliFrame.attr('src', '');
                });

                $videoModal.on('show.bs.modal', function () {
                    $videoModaliFrame.attr('src', $videoModal.data('videourl'));
                });

                $videoModal.on('shown.bs.modal', function () {
                    $newWidth = $videoModal.find('.videoWrapper').width();
                    $videoModaliFrame.attr('width', $newWidth);
                    $videoModaliFrame.attr('height', Math.round($newWidth * $aspectRatio[1] / $aspectRatio[0]));
                });

                $module.on("click", ".data-call-dealer", function (e) {
                    $callDealerModal.modal({
                        show: true,
                        keyboard: true
                    });
                    e.preventDefault();
                });

                $module.on('click', '.email-for-price-anchor', function () {
                    var data = $(this).data();
                    $('#emailfor_comments').val(data["dialogComments"]);
                    $('#price-mile-email-modal-label').html(data["dialogTitle"]);
                });

                $module.on('click', '.email-for-mileage-anchor', function () {
                    var data = $(this).data();
                    $('#emailfor_comments').val(data["dialogComments"]);
                    $('#price-mile-email-modal-label').html(data["dialogTitle"]);
                });

                $(window).resize(function () {
                    if ($videoModal.hasClass('in') === true) {
                        $newWidth = $videoModal.find('.videoWrapper').width();
                        $videoModaliFrame.attr('width', $newWidth);
                        $videoModaliFrame.attr('height', Math.round($newWidth * $aspectRatio[1] / $aspectRatio[0]));
                    }
                });

                $module.find(".image-carousel")
                    .carousel({
                        interval: false
                    })
                    .swiperight(function () {
                        $(this).carousel("prev");
                        lazy.render();
                    })
                    .swipeleft(function () {
                        $(this).carousel("next");
                        lazy.render();
                    });

                $vinModal.find('input[name="ContactMe"]').click(function () {
                    var vinCheckBox = $vinModal.find('input[name="VinCaptchaShow"]')
                    if (vinCheckBox.hasClass('fillone')) {
                        vinCheckBox.removeClass('fillone');
                    }
                    else {
                        vinCheckBox.addClass('fillone');
                    }
                });

                $photoModal.appendTo("body");
                $videoModal.appendTo("body");
                $vinModal.appendTo("body");
                $successModal.appendTo("body");
                $failureModal.appendTo("body");
                $optionsModal.appendTo("body");
                $inquiryModal.appendTo("body");
                $valuemytradeModal.appendTo("body");
                $textModal.appendTo("body");
            });
    };

    function leadFormTabs(contactBlock) {
        var tabs = contactBlock.find(".nav-tabs li a");

        if (contactBlock.length) {
            if (tabs.length) {
                tabs.click(function () {
                    // update contact block title when tab changes
                    var contactBlockTitle = contactBlock.find("[data-tabs-title]");
                    if (contactBlockTitle) {
                        if ($(this).parent().data("tab") === "text") {
                            contactBlockTitle.text("Text Dealership");
                        } else {
                            contactBlockTitle.text("Email Dealership");
                        }
                    }
                });
            }
        }
    }

    $(function () {
        $("section.details-m1").detailsModule();
        DetailsModule_ShowVideoModalOnLoad();
    });

    $('#text-open-modal').click(function () {
        $('[id*=text-dealership-modal]').addClass('active');
        $('[id*=email-dealership-modal]').removeClass('active');

        $('#liText').addClass('active');
        $('#liEmail').removeClass('active');
        $('#rbText').prop('checked', true);
    });

    $('#email-open-modal').click(function () {
        $('[id*=email-dealership-modal]').addClass('active');
        $('[id*=text-dealership-modal]').removeClass('active');

        $('#liEmail').addClass('active');
        $('#liText').removeClass('active');
        $('#rbEmail').prop('checked', true);
    });

    $('#footer-text-open-modal').click(function () {
        $('[id*=text-dealership-modal]').addClass('active');
        $('[id*=email-dealership-modal]').removeClass('active');

        $('#liText').addClass('active');
        $('#liEmail').removeClass('active');
        $('#rbText').prop('checked', true);
    });

    $('#footer-email-open-modal').click(function () {
        $('[id*=email-dealership-modal]').addClass('active');
        $('[id*=text-dealership-modal]').removeClass('active');

        $('#liEmail').addClass('active');
        $('#liText').removeClass('active');
        $('#rbEmail').prop('checked', true);
    });

    $('.vdp-dealer-info-block__dealer-link-text').click(function () {
        $('[id*=text-dealership-modal]').addClass('active');
        $('[id*=email-dealership-modal]').removeClass('active');

        $('#liText').addClass('active');
        $('#liEmail').removeClass('active');
        $('#rbText').prop('checked', true);
    });    

    DetailsModule_ShowVideoModalOnLoad = function() {
        // Shows video modal on document.ready if URL contains #video and vehicle has a video
        var $videoModal = $(".video-modal");
        var $hash = window.location.hash;
        if ($videoModal && $hash === "#video") {
            $videoModal.modal('show');
            window.history.replaceState("", document.title, window.location.pathname); // removes hash from URL
        }
    };

    DetailsModule_InquiryOnBegin = function () {
        $(this).parents(".modal").modal("hide");
    };

    DetailsModule_InquiryOnSuccess = function(response) {
        var $module = $(this).data("module");
        if (response.success) {
            if (response.message !== "") {
                $module.data("successModal").find(".modal-header > h4").html("Success, please see the VIN below.");
                $module.find(".js-vin-message").html(response.message);
            }
            else {
                $module.data("successModal").find(".modal-header > h4").html("Success! Your message has been sent.")
            }
            $module.data("successModal").modal("show");
        } else {
            $module.data("failureModal").modal("show");
        }

        grecaptcha.ready(function () {
            grecaptcha.execute("6LdvybMgAAAAACtTT_p38LRFXSWMB2ObtjGrtdpZ", { action: 'submit' }).then(function (token) {
                $('.recaptcha-response').val(token);
            });
        });
    };

    DetailsModule_InquiryOnFailure = function(e) {
        var $module = $(this).data("module");
        $module.data("failureModal").modal("show");
    };

    DetailsModule_InquiryOnComplete = function () {
        // Do nothing
    };

})(jQuery);

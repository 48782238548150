(function ($) {
    $.validator.addMethod("phoneCFS", function (phone_number, element) {
        phone_number = phone_number.replace(/\s+/g, "");
        return this.optional(element) || libphonenumber.isValidNumberForRegion(phone_number, "US") || libphonenumber.isValidNumberForRegion(phone_number, "VI");
    }, "Please specify a valid phone number");

    $.validator.addMethod("vin", function (value, element) {
        return this.optional(element) || (!!value && value.length === 17);
    }, "VIN must be 17 characters");

    $.validator.addMethod("enterInfo", function (value, element) {
        return this.optional(element) || /^\s*[0-9a-zA-Z][0-9a-zA-Z ]*$/.test(value);
    }, "Please enter your information.");

    $.validator.addMethod("strictEmail", function (value, element) {
        return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(value);
    }, "Please enter a valid email");
    
    $.validationRules = (function () {
        return {
            "inquiry": {
                ignore: ".ignore",
                rules: {
                    FirstName: {
                        minlength: 2,
                        maxlength: 50,
                        required: true,
                        enterInfo: true
                    },
                    LastName: {
                        minlength: 2,
                        maxlength: 50,
                        required: true,
                        enterInfo: true
                    },
                    Email: {
                        maxlength: 100,
                        required: true,
                        strictEmail: true
                    },
                    Phone: {
                        phoneCFS: true
                    },
                    Comments: {
                        maxlength: 1000
                    },
                    TradeInYear: {
                        maxlength: 4,
                        required: {
                            depends: function (element) {
                                return $(element).closest(".lead-form-trade-in").find(".lead-form-trade-in__checkbox-input").is(":checked");
                            }
                        }
                    },
                    TradeInMake: {
                        required: {
                            depends: function (element) {
                                return $(element).closest(".lead-form-trade-in").find(".lead-form-trade-in__checkbox-input").is(":checked");
                            }
                        }
                    },
                    TradeInModel: {
                        required: {
                            depends: function (element) {
                                return $(element).closest(".lead-form-trade-in").find(".lead-form-trade-in__checkbox-input").is(":checked");
                            }
                        }
                    },
                    TradeInColor: {
                        required: {
                            depends: function (element) {
                                return $(element).closest(".lead-form-trade-in").find(".lead-form-trade-in__checkbox-input").is(":checked");
                            }
                        }
                    },
                    TradeInVIN: {
                        maxlength: 17,
                        vin: true,
                    },
                    Recaptcha: {
                        recaptcha: function (element) {
                            return $(element).attr('validate') !== undefined;
                        }
                    }
                },
                highlight: function (element) {
                    $(element).closest(".form-group").removeClass("has-success").addClass("has-error");
                    $(element).siblings(".form-control-feedback").removeClass("glyphicon-ok").addClass("glyphicon-remove");
                },
                unhighlight: function (element) {
                    $(element).closest(".form-group").removeClass("has-error").addClass("has-success");
                    $(element).siblings(".form-control-feedback").removeClass("glyphicon-remove").addClass("glyphicon-ok");
                },
                errorElement: "span",
                errorClass: "help-block",
                errorPlacement: function (error, element) {
                    if (element.parent(".input-group").length) {
                        error.insertAfter(element.parent());
                    } else {
                        error.insertAfter(element);
                    }
                }
            },
            "text": {
                ignore: ".ignore",
                rules: {
                    FirstName: {
                        minlength: 2,
                        maxlength: 50,
                        required: true,
                        enterInfo: true
                    },
                    LastName: {
                        minlength: 2,
                        maxlength: 50,
                        required: true,
                        enterInfo: true
                    },
                    Phone: {
                        required: true,
                        phoneCFS: true
                    },
                    Email: {
                        maxlength: 100,
                        required: false,
                        strictEmail: true
                    },
                    Comments: {
                        maxlength: 1000
                    },
                    TradeInYear: {
                        maxlength: 4,
                        required: {
                            depends: function (element) {
                                return $(element).closest(".lead-form-trade-in").find(".lead-form-trade-in__checkbox-input").is(":checked");
                            }
                        }
                    },
                    TradeInMake: {
                        required: {
                            depends: function (element) {
                                return $(element).closest(".lead-form-trade-in").find(".lead-form-trade-in__checkbox-input").is(":checked");
                            }
                        }
                    },
                    TradeInModel: {
                        required: {
                            depends: function (element) {
                                return $(element).closest(".lead-form-trade-in").find(".lead-form-trade-in__checkbox-input").is(":checked");
                            }
                        }
                    },
                    TradeInColor: {
                        required: {
                            depends: function (element) {
                                return $(element).closest(".lead-form-trade-in").find(".lead-form-trade-in__checkbox-input").is(":checked");
                            }
                        }
                    },
                    TradeInVIN: {
                        maxlength: 17,
                        vin: true,
                    },
                    Recaptcha: {
                        recaptcha: function (element) {
                            return $(element).attr('validate') !== undefined;
                        }
                    }
                },
                highlight: function (element) {
                    $(element).closest(".form-group").removeClass("has-success").addClass("has-error");
                    $(element).siblings(".form-control-feedback").removeClass("glyphicon-ok").addClass("glyphicon-remove");
                },
                unhighlight: function (element) {
                    $(element).closest(".form-group").removeClass("has-error").addClass("has-success");
                    $(element).siblings(".form-control-feedback").removeClass("glyphicon-remove").addClass("glyphicon-ok");
                },
                errorElement: "span",
                errorClass: "help-block",
                errorPlacement: function (error, element) {
                    if (element.parent(".input-group").length) {
                        error.insertAfter(element.parent());
                    } else {
                        error.insertAfter(element);
                    }
                }
            },
            "request_vin": {
                ignore: ".ignore",
                rules: {
                    Phone: {
                        minlength: 13,
                        require_from_group: [1, ".fillone"]
                    },
                    Email: {
                        strictEmail: true,
                        require_from_group: [1, ".fillone"]
                    },
                    FirstName: {
                        minlength: 2,
                        maxlength: 40,
                        required: function (element) {
                            return $(element).parents(".modal-body").find(".contact-seller").is(":checked");
                        },
                        enterInfo: true
                    },
                    LastName: {
                        minlength: 2,
                        maxlength: 40,
                        required: function (element) {
                            return $(element).parents(".modal-body").find(".contact-seller").is(":checked");
                        },
                        enterInfo: true
                    },
                    Recaptcha: {
                        recaptcha: function (element) {
                            return $(element).attr('validate') !== undefined;
                        }
                    }
                },
                highlight: function (element) {
                    $(element).closest(".form-group").removeClass("has-success").addClass("has-error");
                    $(element).siblings(".form-control-feedback").removeClass("glyphicon-ok").addClass("glyphicon-remove");
                },
                unhighlight: function (element) {
                    $(element).closest(".form-group").removeClass("has-error").addClass("has-success");
                    $(element).siblings(".form-control-feedback").removeClass("glyphicon-remove").addClass("glyphicon-ok");
                },
                errorElement: "span",
                errorClass: "help-block",
                errorPlacement: function (error, element) {
                    if (element.parent(".input-group").length) {
                        error.insertAfter(element.parent());
                    } else {
                        error.insertAfter(element);
                    }
                }
            }
        };
    })();

})(jQuery);

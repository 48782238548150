function Carousel(element) {
    let self = this;
    self.SlideFrom = 0;
    self.SlideTo = 0;
    self.isRight = true;

    self.Element = $(element);

    self.Next = this.Element.find('.carousel-control.right');
    self.Previous = this.Element.find('.carousel-control.left');

    self.Items = self.Element.find('.item.active').parent().children();
    self.Indexer = self.Items.index($(self.Element.find('.item.active')[0]));
    if (self.Indexer === 0) {
        return;
    }
    self.IndexBound = self.Items.length - 1;

    self.Element.data('cfs.carousel', self);
    self.Next.attr('cfs.carousel.master', element.getPath());
    self.Next.attr('cfs.carousel.direction', "1");
    self.Previous.attr('cfs.carousel.master', element.getPath());
    self.Previous.attr('cfs.carousel.direction', "-1");

    _loadAhead(0);
    _getLoadAhead(self.Next);
    _getLoadAhead(self.Previous);

    self.Previous.click(function () {
        _prev(this);
    });

    self.Next.click(function () {
        _next(this);
    });

    function _getLoadAhead(element) {
        var carousel = _getCurrentInstance(element);

        var nextI = (element.attr('cfs.carousel.direction') && parseInt(element.attr('cfs.carousel.direction')) >= 0) ? carousel.Indexer + 1 : carousel.Indexer - 1
        if (nextI < 0) {
            nextI = self.IndexBound;
        }

        if (nextI > carousel.IndexBound) {
            _loadAhead(0);
        }
        else {
            _loadAhead(nextI);
        }
    }

    function _loadAhead(index) {
        var nxtImg = $($('.item').find('img')[index]);
        if (nxtImg.attr('data-lazy')) {
            nxtImg.attr('src', nxtImg.attr('data-lazy'));
            nxtImg.removeAttr('data-lazy');   
        }
    }

    function _next(element) {
        var carousel = _getCurrentInstance(element);
        var nextI = carousel.Indexer;
        self.isRight = true;
        if (nextI > carousel.IndexBound) {
            _slide(carousel, 0);
        }
        else {
            _slide(carousel, nextI);
        }
    }

    function _prev(element) {
        var carousel = _getCurrentInstance(element);
        var nextI = carousel.Indexer;
        self.isRight = false;
        if (nextI < 0) {
            _slide(carousel, carousel.IndexBound);
        }
        else {
            _slide(carousel, nextI);
        }
    }

    function _getCurrentInstance(element) {
        return $($(element).attr('cfs.carousel.master')).data('cfs.carousel');
    }

    function _slide(carousel, index) {
        $(carousel.Items[carousel.Indexer]).removeClass('active');
        carousel.Indexer = index;
        $(carousel.Items[carousel.Indexer]).addClass('active');
    }

    $('.image-carousel.carousel.slide').on('slide.bs.carousel', function (e, index) {
        self.SlideFrom = $(this).find('.active').index();
        self.SlideTo = $(e.relatedTarget).index();

    });
    
    $('.image-carousel.carousel.slide').on('slid.bs.carousel', function (e, index) {
        self.SlideTo = $(this).find('.active').index();
        self.Indexer = self.SlideTo;

        if (self.isRight) {
            _loadAhead(self.Indexer + 1);
        } else {
            _loadAhead(self.Indexer - 1);
        }

        self.isRight = true;
    });
}
var InventoryCarouselModule_GeneralInquiry_success;
var InventoryCarouselModule_GeneralInquiry_failure;
var InventoryCarouselModule_GeneralInquiry_begin;
var InventoryCarouselModule_GeneralInquiry_complete;

(function ($) {
    $.fn.inventoryCarousel = function () {
        $(this)
            .each(function (index, element) {
                var $module = $(element);

                const ariaHide = function () {
                    $(this).attr('aria-hidden', true);
                }
                const ariaShow = function () {
                    $(this).attr('aria-hidden', false);
                }

                var $priceModal = $module.find(".data-email-for-price-modal");
                var $mileageModal = $module.find(".data-email-for-mileage-modal");
                var $successModal = $module.find(".data-modal-success");
                var $failureModal = $module.find(".data-modal-error");

                $module.data("successModal", $successModal);
                $module.data("failureModal", $failureModal);

                $module.find("form").data("module", $module);
                $module.find(".data-email-for-price-form").validate($.validationRules.inquiry);
                $module.find(".data-email-for-mileage-form").validate($.validationRules.inquiry);

                $priceModal.on("show.bs.modal", ariaShow);
                $priceModal.on("hide.bs.modal", ariaHide);
                $mileageModal.on("show.bs.modal", ariaShow);
                $mileageModal.on("hide.bs.modal", ariaHide);

                const $priceComments = $priceModal.find("#carousel-price-comments");
                $priceComments.characterCounter({
                    maxLength: 1000
                });

                const $mileageComments = $mileageModal.find("#carousel-mileage-comments");
                $mileageComments.characterCounter({
                    maxLength: 1000
                });

                var maxLength = $('.h-inventory-carousel-m1 .carousel-item').length;
                var addClass = (maxLength <= 1) ? 'one' : (maxLength === 2) ? 'two'
                    : (maxLength === 3) ? 'three-custom' : (maxLength >= 4) ? 'four-custom' : null

                loadCarousel($(element).find('.data-carouselshowmanymoveone'), maxLength, addClass);

                $module.on("click", ".data-email-for-price", function (event) {
                    var data = $(this).data();
                    var $form = $priceModal.find(".data-email-for-price-form");
                    $form.find("input.data-global-user-id").val(data["globalUserId"]);
                    $form.find("input.data-global-inventory-id").val(data["globalInventoryId"]);
                    $form.find("input.data-vehicle-source-id").val(data["vehicleSourceId"]);
                    $form.find("input.data-vehicle-source-external-id").val(data["vehicleSourceExternalId"]);
                    $form.find("textarea.data-comments-field").val(data["vehicleComments"]);
                    $priceModal.modal({
                        show: true,
                        keyboard: true
                    });
                    event.preventDefault();
                });

                $module.on("click", ".data-email-for-mileage", function (event) {
                    var data = $(this).data();
                    var $form = $mileageModal.find(".data-email-for-mileage-form");
                    $form.find("input.data-global-user-id").val(data["globalUserId"]);
                    $form.find("input.data-global-inventory-id").val(data["globalInventoryId"]);
                    $form.find("input.data-vehicle-source-id").val(data["vehicleSourceId"]);
                    $form.find("input.data-vehicle-source-external-id").val(data["vehicleSourceExternalId"]);
                    $form.find("textarea.data-comments-field").val(data["vehicleComments"]);
                    $mileageModal.modal({
                        show: true,
                        keyboard: true
                    });
                    event.preventDefault();
                });

                $priceModal.appendTo("body");
                $mileageModal.appendTo("body");
                $successModal.appendTo("body");
                $failureModal.appendTo("body");
            });
    };

    $(function () {
        $(".h-inventory-carousel-m1").inventoryCarousel();
    });

    // External Callback Function
    function generalInquirySuccess(data) {
        var $module = $(this).data("module");

        if (data.success) {
            $module.data("successModal").modal("show");
        } else {
            $module.data("failureModal").modal("show");
        }
    }

    InventoryCarouselModule_GeneralInquiry_success = generalInquirySuccess;

    // External Callback Function
    function generalInquiryFailure() {
        $(this).data("module").data("failureModal").modal("show");
    }

    InventoryCarouselModule_GeneralInquiry_failure = generalInquiryFailure;

    // External Callback Function
    function generalInquiryBegin() {
        var $this = $(this);
        var modal = $this.parents(".modal");
        modal.modal("hide");
    }

    InventoryCarouselModule_GeneralInquiry_begin = generalInquiryBegin;

    // External Callback Function
    function generalInquiryComplete() {
        grecaptcha.ready(function () {
            grecaptcha.execute("6LdvybMgAAAAACtTT_p38LRFXSWMB2ObtjGrtdpZ", { action: 'submit' }).then(function (token) {
                $('.recaptcha-response').val(token);
            });
        });
    }

    InventoryCarouselModule_GeneralInquiry_complete = generalInquiryComplete;

})(jQuery);

//var map;
//var marker = null;
//var infoWindow = null;
    (function () {
        function init() {
            var h = $('section.map-locations #ifEmbed').height();
            $('section.map-locations .mapOverlay').css('height', h);
            $('section.map-locations .mapOverlay').css('top', h);
            $('section.map-locations .mapOverlay').css('margin-top', -h);

            $(document).click(function (event) {
                if (!$(event.target).closest('#map-canvas').length) {
                    $('section.map-locations .mapOverlay').css('pointer-events', 'auto');
                }
            });
        }

        function setNewLocation(locationSection) {
            //get the H2 tag from the first location (main dealership)
            var headerTag = locationSection.querySelector(".data-dealer-header");
            var $iframe = $('section.map-locations iframe.ifEmbed');

            //get the Google Maps query to the customer from the custom attribute in the H2 tag
            var mapsQuery = headerTag.getAttribute("data-maps-query");

            if (mapsQuery !== '') {
                $('.map-m1').show();
                $iframe.prop('src', mapsQuery);
            } else {
                $('.map-m1').hide();
            }
        }

        var dealerHeaders = document.querySelectorAll("section.map-locations .locations-section");

        for (var i = 0; i < dealerHeaders.length; i++) {
            var locationSection = dealerHeaders[i];
            var header = locationSection.querySelector(".data-dealer-header");
            (function (location) {
                header.addEventListener("click", function (e) {
                    setNewLocation(location);
                });
            })(locationSection);
        }

        init();

        var primaryLocation = document.querySelector(".data-location-primary");
        // != checks the value
        // !== checks the value and type
        // Not sure if this is supposed to be != below, but it works.
        if (primaryLocation != null) {
            setNewLocation(primaryLocation);
        }
})();
var InventorySingleCarouselModule_GeneralInquiry_success;
var InventorySingleCarouselModule_GeneralInquiry_failure;
var InventorySingleCarouselModule_GeneralInquiry_begin;
var InventorySingleCarouselModule_GeneralInquiry_complete;

(function ($) {

    $.fn.singleCarouselModule = function() {
        $(this)
            .each(function(i, e) {
                var $module = $(e);

                const ariaHide = function () {
                    $(this).attr('aria-hidden', true);
                }
                const ariaShow = function () {
                    $(this).attr('aria-hidden', false);
                }

                var $priceModal = $module.find(".data-email-for-price-modal");
                var $mileageModal = $module.find(".data-email-for-mileage-modal");
                var $successModal = $module.find(".data-modal-success");
                var $failureModal = $module.find(".data-modal-error");

                $module.data("successModal", $successModal);
                $module.data("failureModal", $failureModal);

                $module.find("form").data("module", $module);


                $module.find(".data-email-for-price-form").validate($.validationRules.inquiry);
                $module.find(".data-email-for-mileage-form").validate($.validationRules.inquiry);

                $priceModal.on("show.bs.modal", ariaShow);
                $priceModal.on("hide.bs.modal", ariaHide);
                $mileageModal.on("show.bs.modal", ariaShow);
                $mileageModal.on("hide.bs.modal", ariaHide);

                const $priceComments = $priceModal.find("#single-carousel-price-comments");
                $priceComments.characterCounter({
                    maxLength: 1000
                });

                const $mileageComments = $mileageModal.find("#single-carousel-mileage-comments");
                $mileageComments.characterCounter({
                    maxLength: 1000
                });

                var $carousel = $module.find(".carousel");

                var length = $('.h-inventory-singlecarousel-m1 .carousel-item').length;
                if (length > 1) {
                	$carousel.carousel({
                		interval: 4000
                	}).swipeleft(function () {
                		$(this).carousel("next");
                	}).swiperight(function () {
                		$(this).carousel("prev");
                	});
                } else {
                	$('.h-inventory-singlecarousel-m1 .left').hide();
                	$('.h-inventory-singlecarousel-m1 .right').hide();
                }

                $module.on("click",
                    ".data-email-for-price",
                    function(e) {
                        var data = $(this).data();
                        var $form = $priceModal.find(".data-email-for-price-form");
                        $form.find("input.data-global-user-id").val(data["globalUserId"]);
                        $form.find("input.data-global-inventory-id").val(data["globalInventoryId"]);
                        $form.find("input.data-vehicle-source-id").val(data["vehicleSourceId"]);
                        $form.find("input.data-vehicle-source-external-id").val(data["vehicleSourceExternalId"]);
                        $form.find("textarea.data-comments-field").val(data["vehicleComments"]);
                        $priceModal.modal({
                            show: true,
                            keyboard: true
                        });
                        e.preventDefault();
                    });

                $module.on("click",
                    ".data-email-for-mileage",
                    function(e) {
                        var data = $(this).data();
                        var $form = $mileageModal.find(".data-email-for-mileage-form");
                        $form.find("input.data-global-user-id").val(data["globalUserId"]);
                        $form.find("input.data-global-inventory-id").val(data["globalInventoryId"]);
                        $form.find("input.data-vehicle-source-id").val(data["vehicleSourceId"]);
                        $form.find("input.data-vehicle-source-external-id").val(data["vehicleSourceExternalId"]);
                        $form.find("textarea.data-comments-field").val(data["vehicleComments"]);
                        $mileageModal.modal({
                            show: true,
                            keyboard: true
                        });
                        e.preventDefault();
                    });

                $priceModal.appendTo("body");
                $mileageModal.appendTo("body");
                $successModal.appendTo("body");
                $failureModal.appendTo("body");
            });
    };

    // External Callback Function
    InventorySingleCarouselModule_GeneralInquiry_success = function (data) {
        var $module = $(this).data("module");

        if (data.success) {
            $module.data("successModal").modal("show");
        } else {
            $module.data("failureModal").modal("show");
        }
    }

    // External Callback Function
    InventorySingleCarouselModule_GeneralInquiry_failure = function () {
        $(this).data("module").data("failureModal").modal("show");
    }

    // External Callback Function
    InventorySingleCarouselModule_GeneralInquiry_begin = function () {
        var $this = $(this);
        var modal = $this.parents(".modal");
        modal.modal("hide");
    }

    // External Callback Function
    InventorySingleCarouselModule_GeneralInquiry_complete = function () {
        grecaptcha.ready(function () {
            grecaptcha.execute("6LdvybMgAAAAACtTT_p38LRFXSWMB2ObtjGrtdpZ", { action: 'submit' }).then(function (token) {
                $('.recaptcha-response').val(token);
            });
        });
    }

    $(function() {
        $(".h-inventory-singlecarousel-m1").singleCarouselModule();
    });

})(jQuery);

$(function () {
    function handleBody($element) {
        var div = $("<div />");

        var imageUrl = $element.data("backgroundImage");

        div.css({
            content: "",
            position: "fixed",
            left: "0",
            right: "0",
            bottom: "0",
            top: "0",
            zIndex: -200,
            display: "block",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 50%",
            backgroundImage: "url(" + imageUrl + ")"
        });

        $("body").append(div);
    }

    var windowWidth = $(window).width();

    if (windowWidth > 767) {
        var $backgroundElements = $("[data-background-image]");
        $backgroundElements.each(function (index, element) {
            var $element = $(element);
            var imageUrl = $element.data("backgroundImage");
            var imagePosition = $element.data("backgroundImagePosition");
            imagePosition = typeof (imagePosition) === "undefined" ? "fixed" : imagePosition;

            var elementClass = $element.attr('class');
            var isHeader = elementClass.includes('header');

            if (imageUrl === "") {
                return;
            }

            if ($element.is("body") && $(window).width() > 767) {
                handleBody($element);
                return;
            }

            switch (imagePosition) {
                case "parallax":
                    if (isHeader) {
                        break;
                    }
                    else {
                        $element.parallax({ imageSrc: imageUrl });
                    }
                    break;
                default:
                    console.warn("unknown image position '" + imagePosition + "'; using fixed");
                case "fixed":
                    //$element.css('background-image', 'url("' + imageUrl + '")');
                    $element.css({
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "50% 50%",
                        backgroundImage: "url(" + imageUrl + ")"
                    });

                    break;
            }
        });
        // Add alt attributes to each parallax image for accessibility
        $('.parallax-mirror img').attr('alt', '');
    }
});
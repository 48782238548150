(function($) {
    
    $.fn.findAVehicle = function() {
        $(this)
            .each(function(i, e) {
                var $module = $(e);

                var $makeSelect = $module.find(".data-make");
                var $modelSelect = $module.find(".data-model");

                $makeSelect.change(function() {
                    var val = $(this).val();

                    $modelSelect.html($("<option />").val("").text("All Models")).val("");

                    if (val !== "") {
                        $modelSelect.attr("disabled", true);

                        var url = $(this).data("modelsEndpoint");

                        $.ajax({
                            type: "POST",
                            url: url,
                            data: { 'Make': val },
                            dataType: "json",
                            success: function(data) {
                                $.each(data,
                                    function(i, e) {
                                        $modelSelect.append($("<option>", { value: e.Name, text: e.Name }));
                                    });
                                $modelSelect.removeAttr("disabled");
                            }
                        });

                    } else {
                        $(this).val("");
                        $modelSelect.attr("disabled", true);
                    }
                });
            });
    };

    $(function() {
        $(".h-find-m1").findAVehicle();
    });

})(jQuery);

(function($) {

    $.fn.inventoryCarouselCompact = function () {
        $(this)
            .each(function(i, e) {
                var maxLength = $('.h-inventory-carouselcompact-m1 .carousel-item').length;
                var addClass = (maxLength <= 1) ? 'one' : (maxLength === 2) ? 'two'
                    : (maxLength === 3) ? 'three' : (maxLength >= 4) ? 'four' : null;
                loadCarousel($(e).find('.data-carouselshowmanymoveone'), maxLength, addClass);
            });
    };

    $(function () {
        $(".h-inventory-carouselcompact-m1").inventoryCarouselCompact();
    });

})(jQuery);
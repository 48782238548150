// module exports
var ContactUsModule_GeneralInquiryOnBegin;
var ContactUsModule_GeneralInquiryOnSuccess;
var ContactUsModule_GeneralInquiryOnFailure;

var ContactUsModule_ServiceRequestOnBegin;
var ContactUsModule_ServiceRequestOnSuccess;
var ContactUsModule_ServiceRequestOnFailure;
var ContactUsModule_ServiceRequestOnComplete;

var ContactUsModule_CarFinderOnBegin;
var ContactUsModule_CarFinderOnSuccess;
var ContactUsModule_CarFinderOnFailure;
var ContactUsModule_CarFinderOnComplete;

var ContactUsModule_AppraisalOnBegin;
var ContactUsModule_AppraisalOnSuccess;
var ContactUsModule_AppraisalOnFailure;
var ContactUsModule_AppraisalOnComplete;

(function ($) {
    var validationRules = (function () {
        // All this function does is return the validation data structure for the contact forms.
        // This is rather unsightly and VS lets you collapse function blocks, but not JSON objects,
        // so this is a trick to let you hide the big block of rules.
        return { //TODO: Non-general lead forms need some type of enforcement on maxlengths, probably
            'general': {
                ignore: ".ignore",
                rules: {
                    FirstName: {
                        minlength: 2,
                        maxlength: 50,
                        required: true,
                        enterInfo: true
                    },
                    LastName: {
                        minlength: 2,
                        maxlength: 50,
                        required: true,
                        enterInfo: true
                    },
                    Email: {
                        maxlength: 100,
                        required: true,
                        strictEmail: true
                    },
                    Phone: {
                        phoneCFS: true
                    },
                    Comments: {
                        maxlength: 1000
                    },
                    Recaptcha: {
                        recaptcha: function (element) { return $(element).attr('validate') !== undefined; }
                    }
                },
                highlight: function(element) {
                    $(element).closest(".form-group").removeClass("has-success").addClass("has-error");
                    $(element)
                        .siblings(".form-control-feedback")
                        .removeClass("glyphicon-ok")
                        .addClass("glyphicon-remove");
                },
                unhighlight: function(element) {
                    $(element).closest(".form-group").removeClass("has-error").addClass("has-success");
                    $(element)
                        .siblings(".form-control-feedback")
                        .removeClass("glyphicon-remove")
                        .addClass("glyphicon-ok");
                },
                errorElement: "span",
                errorClass: "help-block",
                errorPlacement: function(error, element) {
                    if (element.parent(".input-group").length) {
                        error.insertAfter(element.parent());
                    } else {
                        error.insertAfter(element);
                    }
                }
            },
            'service': {
                ignore: ".ignore",
                rules: {
                    FirstName: {
                        minlength: 2,
                        maxlength: 50,
                        required: true,
                        enterInfo: true
                    },
                    LastName: {
                        minlength: 2,
                        maxlength: 50,
                        required: true,
                        enterInfo: true
                    },
                    Email: {
                        maxlength: 100,
                        required: true,
                        strictEmail: true
                    },
                    Phone: {
                        phoneCFS: true
                    },
                    Comments: {
                        maxlength: 500
                    },
                    Recaptcha: {
                        recaptcha: function (element) { return $(element).attr('validate') !== undefined; }
                    }
                },
                highlight: function(element) {
                    $(element).closest(".form-group").removeClass("has-success").addClass("has-error");
                    $(element)
                        .siblings(".form-control-feedback")
                        .removeClass("glyphicon-ok")
                        .addClass("glyphicon-remove");
                },
                unhighlight: function(element) {
                    $(element).closest(".form-group").removeClass("has-error").addClass("has-success");
                    $(element)
                        .siblings(".form-control-feedback")
                        .removeClass("glyphicon-remove")
                        .addClass("glyphicon-ok");
                },
                errorElement: "span",
                errorClass: "help-block",
                errorPlacement: function(error, element) {
                    if (element.parent(".input-group").length) {
                        error.insertAfter(element.parent());
                    } else {
                        error.insertAfter(element);
                    }
                }
            },
            'carFinder': {
                ignore: ".ignore",
                rules: {
                    FirstName: {
                        minlength: 2,
                        maxlength: 50,
                        required: true,
                        enterInfo: true
                    },
                    LastName: {
                        minlength: 2,
                        maxlength: 50,
                        required: true,
                        enterInfo: true
                    },
                    Email: {
                        maxlength: 100,
                        required: true,
                        strictEmail: true
                    },
                    Phone: {
                        phoneCFS: true
                    },
                    Year: {
                        minlength: 4,
                        maxlength: 4,
                        required: true,
                        number: true
                    },
                    Make: {
                        minlength: 2,
                        maxlength: 30,
                        required: true,
                        enterInfo: true
                    },
                    Model: {
                        minlength: 2,
                        maxlength: 50,
                        required: true,
                        enterInfo: true
                    },
                    Mileage: {
                        maxlength: 7,
                        number: true
                    },
                    Color: {
                        maxlength: 30
                    },
                    Options: {
                        maxlength: 500
                    },
                    Comments: {
                        maxlength: 500
                    },
                    Recaptcha: {
                        recaptcha: function (element) { return $(element).attr('validate') !== undefined; }
                    }
                },
                highlight: function(element) {
                    $(element).closest(".form-group").removeClass("has-success").addClass("has-error");
                    $(element)
                        .siblings(".form-control-feedback")
                        .removeClass("glyphicon-ok")
                        .addClass("glyphicon-remove");
                },
                unhighlight: function(element) {
                    $(element).closest(".form-group").removeClass("has-error").addClass("has-success");
                    $(element)
                        .siblings(".form-control-feedback")
                        .removeClass("glyphicon-remove")
                        .addClass("glyphicon-ok");
                },
                errorElement: "span",
                errorClass: "help-block",
                errorPlacement: function(error, element) {
                    if (element.parent(".input-group").length) {
                        error.insertAfter(element.parent());
                    } else {
                        error.insertAfter(element);
                    }
                }
            },
            'appraisal': {
                ignore: ".ignore",
                rules: {
                    FirstName: {
                        minlength: 2,
                        maxlength: 50,
                        required: true,
                        enterInfo: true
                    },
                    LastName: {
                        minlength: 2,
                        maxlength: 50,
                        required: true,
                        enterInfo: true
                    },
                    Email: {
                        maxlength: 100,
                        required: true,
                        strictEmail: true
                    },
                    Phone: {
                        minlength: 10,
                        maxlength: 20,
                        required: true,
                        phoneCFS: true
                    },
                    Year: {
                        minlength: 4,
                        maxlength: 4,
                        required: true,
                        number: true
                    },
                    Make: {
                        minlength: 2,
                        maxlength: 40,
                        required: true,
                        enterInfo: true
                    },
                    Model: {
                        minlength: 2,
                        maxlength: 40,
                        required: true,
                        enterInfo: true
                    },
                    Mileage: {
                        maxlength: 7,
                        number: true,
                        required: true
                    },
                    Engine: {
                        maxlength: 30
                    },
                    Transmission: {
                        maxlength: 30
                    },
                    ClearTitle: {
                        required: true
                    },
                    HasBeenInWreck: {
                        required: true
                    },
                    Comments: {
                        maxlength: 500
                    },
                    Recaptcha: {
                        recaptcha: function (element) { return $(element).attr('validate') !== undefined; }
                    }
                },
                highlight: function(element) {
                    $(element).closest(".form-group").removeClass("has-success").addClass("has-error");
                    $(element)
                        .siblings(".form-control-feedback")
                        .removeClass("glyphicon-ok")
                        .addClass("glyphicon-remove");
                },
                unhighlight: function(element) {
                    $(element).closest(".form-group").removeClass("has-error").addClass("has-success");
                    $(element)
                        .siblings(".form-control-feedback")
                        .removeClass("glyphicon-remove")
                        .addClass("glyphicon-ok");
                },
                errorElement: "span",
                errorClass: "help-block",
                errorPlacement: function(error, element) {
                    if (element.parent(".radio-inline").length) {
                        error.appendTo(element.parent().parent());
                    } else {
                        error.insertAfter(element);
                    }
                }
            }
        };
    })();

    $.fn.contactModule = function () {
        $(this)
            .each(function(i, e) {
                var $module = $(e);

                var $successModal = $module.find(".data-modal-success");
                var $failureModal = $module.find(".data-modal-error");

                $module.find("form").data("module", $module);

                $module.data("successModal", $successModal);
                $module.data("failureModal", $failureModal);

                let date = new Date();
                date.setHours(0, 0, 0, 0);

                $module.find(".datetimepicker1").datetimepicker({
                    format: "MMMM DD, YYYY",
                    minDate: date
                });

                const $generalComments = $module.find("#general-comments");
                $generalComments.characterCounter({
                    maxLength: 1000
                });
                const $serviceComments = $module.find("#service-comments");
                $serviceComments.characterCounter({
                    maxLength: 500
                });
                const $finderOptions = $module.find("#finder-options");
                $finderOptions.characterCounter({
                    maxLength: 500
                });
                const $finderComments = $module.find("#finder-comments");
                $finderComments.characterCounter({
                    maxLength: 500
                });
                const $appraisalComments = $module.find("#appraisal-comments");
                $appraisalComments.characterCounter({
                    maxLength: 500
                });

                $module.find(".service,.carFinder,.appraisal").hide();

                $module.find('select[name="contactForm"]')
                    .change(function () {
                        var val = $(this).val();

                        if (val === "general") {
                            $(".general").show();
                        } else {
                            $(".general").hide();
                        }

                        if (val === "service") {
                            $(".service").show();
                        } else {
                            $(".service").hide();
                        }

                        if (val === "carFinder") {
                            $(".carFinder").show();
                        } else {
                            $(".carFinder").hide();
                        }

                        if (val === "appraisal") {
                            $(".appraisal").show();
                        } else {
                            $(".appraisal").hide();
                        }
                    });

                $module.find(".general form").validate(validationRules.general);
                $module.find(".service form").validate(validationRules.service);
                $module.find(".carFinder form").validate(validationRules.carFinder);
                $module.find(".appraisal form").validate(validationRules.appraisal);
                
                $successModal.appendTo("body");
                $failureModal.appendTo("body");
            });
    };

    $(function() {
        $(".contact-m1").contactModule();
    });

    ContactUsModule_GeneralInquiryOnBegin = function () {
        // Do nothing
    };
    ContactUsModule_GeneralInquiryOnSuccess = function (response) {
        var $module = $(this).data("module");
        if (response.success) {
            $module.data("successModal").modal("show");
        } else {
            $module.data("failureModal").modal("show");
        }

        grecaptcha.ready(function () {
            grecaptcha.execute("6LdvybMgAAAAACtTT_p38LRFXSWMB2ObtjGrtdpZ", { action: 'submit' }).then(function (token) {
                $('.recaptcha-response').val(token);
            });
        });
    };
    ContactUsModule_GeneralInquiryOnFailure = function (error) {
        var $module = $(this).data("module");
        $module.data("failureModal").modal("show");
    };
    ContactUsModule_GeneralInquiryOnComplete = function () {
        // Do nothing
    };

    ContactUsModule_ServiceRequestOnBegin = function () {
        // Do nothing
    };
    ContactUsModule_ServiceRequestOnSuccess = function (response) {
        var $module = $(this).data("module");
        if (response.success) {
            $module.data("successModal").modal("show");
        } else {
            $module.data("failureModal").modal("show");
        }

        grecaptcha.ready(function () {
            grecaptcha.execute("6LdvybMgAAAAACtTT_p38LRFXSWMB2ObtjGrtdpZ", { action: 'submit' }).then(function (token) {
                $('.recaptcha-response').val(token);
            });
        });
    };
    ContactUsModule_ServiceRequestOnFailure = function (error) {
        var $module = $(this).data("module");
        $module.data("failureModal").modal("show");
    };
    ContactUsModule_ServiceRequestOnComplete = function () {
        var id = $(this).data('captchaId');
        grecaptcha.reset(id);
    };

    ContactUsModule_CarFinderOnBegin = function () {
        // Do nothing
    };
    ContactUsModule_CarFinderOnSuccess = function (response) {
        var $module = $(this).data("module");
        if (response.success) {
            $module.data("successModal").modal("show");
        } else {
            $module.data("failureModal").modal("show");
        }

        grecaptcha.ready(function () {
            grecaptcha.execute("6LdvybMgAAAAACtTT_p38LRFXSWMB2ObtjGrtdpZ", { action: 'submit' }).then(function (token) {
                $('.recaptcha-response').val(token);
            });
        });
    };
    ContactUsModule_CarFinderOnFailure = function (error) {
        var $module = $(this).data("module");
        $module.data("failureModal").modal("show");
    };
    ContactUsModule_CarFinderOnComplete = function () {
        // Do nothing
    };

    ContactUsModule_AppraisalOnBegin = function () {
        // Do nothing
    };
    ContactUsModule_AppraisalOnSuccess = function (response) {
        var $module = $(this).data("module");
        if (response.success) {
            $module.data("successModal").modal("show");
        } else {
            $module.data("failureModal").modal("show");
        }
    };
    ContactUsModule_AppraisalOnFailure = function (error) {
        var $module = $(this).data("module");
        $module.data("failureModal").modal("show");
    };
    ContactUsModule_AppraisalOnComplete = function () {
        // Do nothing
    };

})(jQuery);

$(document).ready(function () {
	//Set form if graphics provided a proper query string
	var contactForm = getParameterByName('form');
	if (contactForm == 'service') {
		$('#contactForm').val('service').change();

	} else if (contactForm == 'appraisal') {
		$('#contactForm').val('appraisal').change();

	} else if (contactForm == 'carfinder') {
		$('#contactForm').val('carFinder').change();
	} else {
		//general; the default
	}
});

function getParameterByName(name, url) {
	if (!url) url = window.location.href;
	name = name.replace(/[\[\]]/g, '\\$&');
	var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
function MobileModalIosInputFix() {
    /* https://stackoverflow.com/questions/8348139/detect-ios-version-less-than-5-with-javascript/14223920#14223920 */
    if (!/iP(hone|od|ad)/.test(navigator.platform) || parseInt((navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/)[1], 10) !== 11) {
        return;
    }

    var scrollPosition = 0;
    $(".modal").off('shown.bs.modal', MobileInputSolution.Show).on('shown.bs.modal', MobileInputSolution.Show);
    $(".modal").off('hidden.bs.modal', MobileInputSolution.Hide).on('hidden.bs.modal', MobileInputSolution.Hide);
    $(".collapse").off('shown.bs.collapse', MobileInputSolution.Show).on('shown.bs.collapse', MobileInputSolution.Show);
    $(".collapse").off('hidden.bs.collapse', MobileInputSolution.Hide).on('hidden.bs.collapse', MobileInputSolution.Hide);
}

var MobileInputSolution = {
    Hide: function (e) {
        if (!$(this).hasClass('not-full-screen')) {
            document.getElementsByTagName("body")[0].style.position = "static";
            window.scrollTo(0, scrollPosition);
        }
    },
    Show: function (e) {
        var doc = document.documentElement;
        if (!$(this).hasClass('not-full-screen')) {
            scrollPosition = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
            document.getElementsByTagName("body")[0].style.position = "fixed";
        }
    }
};

function loadCarousel($carousel, maxLength, addClass) {

    if (addClass) {
        $carousel.find(".carousel-control")
            .each(function () {
                $(this).addClass(addClass);
            });
    }

    if (maxLength > 4) {
        $carousel.carousel({
            interval: 4000
        }).swipeleft(function () {
            $(this).carousel("next");
        }).swiperight(function () {
            $(this).carousel("prev");
        });
        maxLength = (maxLength === 1) ? 1 : 4;
    }

    $carousel.find(".carousel-item")
        .each(function () {
            if (maxLength > 1) {
                var next = $(this).next();
                if (!next.length) {
                    next = $(this).siblings(":first");
                }
                next.children(":first-child").clone().addClass("extra1").appendTo($(this));

                for (var i = 0; i < (maxLength - 2); i++) {
                    next = next.next();
                    if (!next.length) {
                        next = $(this).siblings(":first");
                    }

                    next.children(":first-child").clone().addClass("extra" + (i + 2)).appendTo($(this));
                }
            }
        });
}

function toggleTradeInFields() {
    const prefix = "lead-form-trade-in__";
    const tradeInCheckboxContainers = $(`.${prefix}checkbox`);
    
    if (tradeInCheckboxContainers.length) {
        tradeInCheckboxContainers.each(function () {
            const checkboxContainer = $(this);
            const checkbox = checkboxContainer.find(`.${prefix}checkbox-input`);
            const fields = checkboxContainer.next(`.${prefix}fields`);

            checkbox.change(function () {
                if (checkbox.is(':checked')) {
                    fields.addClass(`${prefix}fields--open`);
                } else {
                    fields.removeClass(`${prefix}fields--open`);
                }
            });
        });
    }
}

function toggleServicesDropdown() {
    var servicesToggle = $(".lead-form-services__toggle");
    var servicesOptions = servicesToggle.next(".lead-form-services__options");
    
    if (servicesToggle.length && servicesOptions.length) {
        servicesToggle.click(function() {
            servicesOptions.toggleClass("lead-form-services__options--open");
        });
    }
}

function toggleOnpageServicesDropdown() {
    var servicesToggle = $(".on-page-form-services__toggle");
    var servicesOptions = servicesToggle.next(".on-page-form-services__options");

    if (servicesToggle.length && servicesOptions.length) {
        servicesToggle.click(function () {
            servicesOptions.toggleClass("on-page-form-services__options--open");
        });
    }
}

function toggleShowMore() {
    var toggleButtons = $('.show-more-area__toggle');
    
    if (toggleButtons.length) {
        toggleButtons.each(function() {
            var toggleButton = $(this);
            var container = toggleButton.closest('.show-more-area');

            toggleButton.click(function () {
                if (container.hasClass("show-more-area--expanded")) {
                    container.removeClass("show-more-area--expanded");
                    toggleButton.text(toggleButton.data("moreText") ? toggleButton.data("moreText") : "Show More");
                } else {
                    container.addClass("show-more-area--expanded");
                    toggleButton.text(toggleButton.data("lessText") ? toggleButton.data("lessText") : "Show Less");
                }
            });
        });
    }
}

function adjustStickyFooterPosition() {
    $(window).on("resize", function () {
        var stickyFooter = $(".sticky-lead-footer:visible");
        var pageFooter = $(".footer:visible");

        if (stickyFooter && stickyFooter.length && pageFooter && pageFooter.length) {
            pageFooter.css("margin-bottom", stickyFooter.outerHeight());
        } else {
            pageFooter.removeAttr("style");
        }
    }).resize();
}

jQuery.fn.extend({
    getPath: function () {
        var pathes = [];

        this.each(function (index, element) {
            var path, $node = jQuery(element);

            while ($node.length) {
                var realNode = $node.get(0), name = realNode.localName;
                if (!name) { break; }

                name = name.toLowerCase();
                var parent = $node.parent();
                var sameTagSiblings = parent.children(name);

                if (sameTagSiblings.length > 1) {
                    allSiblings = parent.children();
                    let index = allSiblings.index(realNode) + 1;
                    if (index > 0) {
                        name += ':nth-child(' + index + ')';
                    }
                }

                path = name + (path ? ' > ' + path : '');
                $node = parent;
            }

            pathes.push(path);
        });

        return pathes.join(',');
    }
});

$(document).ready(function () {
    toggleTradeInFields();
    toggleServicesDropdown();
    toggleOnpageServicesDropdown();
    toggleShowMore();
    MobileModalIosInputFix();
    
    var lazyImages = $('img.default-src');
    if (lazyImages.length) {
        var loadingImage = lazyImages.filter(':last').attr('src');

        $('.collapse').on('hidden.bs.collapse shown.bs.collapse', function () {
            jQuery(window).trigger('resize');
        });
        lazyImages.reload({
            reloadClass: 'loading-image',
            reloadTime: 4000,
            retryCount: 5,
            loadingImage: loadingImage,
            failedImage: loadingImage.replace('.png', '_unavailable.png')
        });
    }

    if (lazy) {
        lazy.init({
            offset: 1000,
            throttle: 150
        });
    }

    $('.photo-stream-modal').on('shown.bs.modal', function (e) {
        if (lazy) {
            lazy.render();
        }
    })

    if ($('.image-carousel.carousel.slide').length && typeof Carousel !== 'undefined') {
        var __carousel = new Carousel($('.image-carousel.carousel.slide'));
    }

    $('.phone_us').mask("(000) 000-0000");
    
    $('.input-numeric').on("input", function () {
        this.value = this.value.replace(/[^0-9]/g, "");
    });

    // Add DataDome Bot Checking Script
    !function (a, b, c, d, e, f) { a.ddjskey = e; a.ddoptions = f || null; var m = b.createElement(c), n = b.getElementsByTagName(c)[0]; m.async = 1, m.src = d, n.parentNode.insertBefore(m, n) }(window, document, "script", "https://js.datadome.co/tags.js", "78E75958F5D8D06268C14F1B1AAB5B", { ajaxListenerPath: true, allowHtmlContentTypeOnCaptcha: true });
});
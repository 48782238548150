(function ($) {
    $.valHooks.textarea = {
        set: function (elem) {
            // Compensate for race condition when .val() is called
            window.setTimeout(function () {
                // Compensate for lack of 'input' firing when .val() is called
                $(elem).trigger('characterCounter.update');
            }, 10);
        }
    };

    const updateCounter = function ($commentsObject, $counterObject, maxLength) {
        const textLength = $commentsObject.val().length;
        const counterText = `${textLength}/${maxLength}`;

        $counterObject.text(counterText);

        if (textLength > maxLength) {
            $counterObject.addClass('counter-error');
        } else {
            $counterObject.removeClass('counter-error');
        }
    };

    $.fn.characterCounter = function (options) {
        const $self = $(this);
        const $messageCounter = $('<p class="message-counter"></p>').insertAfter($self);

        $self.on('input', function () { updateCounter($self, $messageCounter, options.maxLength); });

        $self.on('characterCounter.update', function () { updateCounter($self, $messageCounter, options.maxLength); });

        updateCounter($self, $messageCounter, options.maxLength);
    };
})(jQuery);

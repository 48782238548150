$(function () {
    var $navm1 = $(".nav-m1");
    var $mainMenu = $(".main-menu");
    var $navbarHeader = $(".navbar-header");
    var $navbarToggle = $(".navbar-toggle");
    var $navbarMenu = $($navbarToggle.data("target"));

    var onMenuExpand = function () {
        // This is how much space should exist above the navbar menu
        var spaceAboveMenu = $navbarHeader.position().top + $navbarHeader.height();

        // We can use calc to ensure our menu stays within viewport
        var calculatedHeight = "calc(100vh - " + spaceAboveMenu + "px)";

        // Apply the calculated height as CSS max-height
        $navbarMenu.css({
            maxHeight: calculatedHeight
        });
    };

    var onMenuCollapse = function () {
        // Reset max-height when collapsed
        $navbarMenu.css({
            maxHeight: ''
        });
    };

    // If we have a main menu
    if ($mainMenu.length) {

        // Makes navigation sticky
        $mainMenu.customAffix({
            placeholder: $navm1,
            classes: "affix"
        });

        // Add hooks into navbar menu collapse events
        $navbarMenu.on('shown.bs.collapse', onMenuExpand);
        $navbarMenu.on('hidden.bs.collapse', onMenuCollapse);
    }

    $navbarToggle.on("click", function () {
        // Toggle active class navbar toggle button
        $navbarToggle.toggleClass("active");
    });

    $(".navbar-nav a[href^='#']").on("click", function (e) {
        // Get the element referenced in the hash
        var targetElement = $(this.hash);

        // If it exists
        if (targetElement.length) {
            e.preventDefault();

            // Scroll to that element
            $("html, body").animate({
                scrollTop: targetElement.offset().top
            }, 700, function () {
                // Then update the address bar
                window.location.hash = this.hash;
            });
        }
    });
});
